@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700&display=swap);
html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nunito", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e9ecef;
  color: #868ba1;
  /* overflow: hidden; */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.react-calendar {
  max-width: 270px;
  border-color: rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-family: inherit;
  font-size: inherit;
  padding: 10px;
  margin: 1px 0 0;
  border-radius: 3px;
  width: auto !important;
  z-index: 5 !important;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  transition: all 0.2s ease-in-out;
  padding: 5px;
  background-color: #f8f9fa;
  color: #868ba1;
  padding: 6px 10px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  border: 0;
  border-radius: 1px;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  display: flex;
}
.react-calendar__navigation button {
  min-width: 44px;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #868ba1;

  letter-spacing: 1px;
  border: 0;
  background-color: transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
  background-color: #fff;
}
.react-calendar__month-view__weekdays {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 1px;
  /* padding: 6px 10px; */
  color: #a3a7b7;
  text-align: center;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar button.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
  outline: none;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
  outline: none;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation > .react-calendar__navigation__arrow {
  font-size: 20px;
}
.react-calendar button.react-calendar__month-view__days__day {
  border: 1px solid #fff;
  border-radius: 1px;
}

.react-calendar button.react-calendar__month-view__days__day--neighboringMonth {
  color: #ccc;
}
.react-calendar button.react-calendar__tile--active {
  background: #14ec8e;
  color: white;
}

.react-calendar button.react-calendar__tile--active:focus {
  background: #14ec8e;
  color: white;
  outline: none;
}

.react-calendar button.react-calendar__tile--active:active {
  outline: none;
}

.react-calendar button.react-calendar__tile--active:visited {
  outline: none;
}
.react-calendar button.react-calendar__month-view__days__day.highlight {
  background-color: #14ec8e6b;
}
.react-calendar button.react-calendar__tile--active.highlight {
  background: #14ec8e;
}

